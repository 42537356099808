import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import Form from 'components/forms/connected-support'
import { useToggle } from '~/hooks/use-toggle'

const ExpertAlertsPage = ({ data }) => {
  const { heroImage } = data

  const [formModalOpen, toggleFormModal] = useToggle()

  return (
    <Layout>
      <Helmet>
        <title>Expert Alerts | Hutson Inc</title>
      </Helmet>

      <Hero image={heroImage.childImageSharp.gatsbyImageData} title='Expert Alerts' />

      <Content kind='full'>
        <H2>Prevent unplanned downtime with predictive diagnostics</H2>
        <P>
          Expert Alert rules are developed by teams of John Deere product subject matter experts.
          They analyze data that is returned from machines, creating alerts based on trends and
          other indicators of potential imminent failure.
        </P>
        <Spacer variable />
        <H2>How Expert Alerts work</H2>
        <P>
          Given your consent, we can enable Expert Alerts on your machine to utilize it's monitoring
          capabilities and proactively identify potential issues. Someone from the Hutson Connected
          Support Team or your local service department will contact you in the event an Expert
          Alert triggers. We notify every member of your Hutson Team from your salesperson to your
          local service department so everyone is in the loop.
        </P>
        <P>
          These alerts allows our team to remotely diagnose issues and resolve them before they
          become a problem. We can help determine issues that need to be addressed immediate, or, if
          the repair can wait, we can schedule a convenient time to make the repair with minimal
          disruption to your operation or bottom line.
        </P>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button ghost color='green' onClick={toggleFormModal}>
            Request more information
          </Button>
        </ButtonContainer>
      </Content>

      <Form toggleModal={toggleFormModal} modalOpen={formModalOpen} />
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query expertAlertsPageQuery {
    heroImage: file(relativePath: { eq: "connected-support/expert-alerts-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ExpertAlertsPage
